import React from "react"
import { Link, graphql } from "gatsby"
import { Avatar, Layout, SEO } from "../components"

const Thankyou = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  //   const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <div className="thankyou">
        <div className="text [ flow ]">
          <p>Thank you for reaching out. I'll get back to you shortly.</p>
          <div className="[ back-to-somewhere ]">
            <Link to="/" className="[ link-hover color-tertiary ]">Back to Home</Link>
          </div>
        </div>
        <Avatar />
      </div>
    </Layout>
  )
}

export default Thankyou

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
// allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
//   edges {
//     node {
//       excerpt
//       fields {
//         slug
//       }
//       frontmatter {
//         date(formatString: "MMMM DD, YYYY")
//         title
//         description
//       }
//     }
//   }
// }
